<template>
  <div>
    <div class="title">
      <div style="font-size: 30px">En construcción</div>
    </div>

    <div class="father-text lighten-4">
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="12">
          <div outlined tile class="pa-10 question">En construcción</div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>

<style scoped>
.title {
  color: black;
  margin-top: 30px;
  font-size: 12em;
  margin-left: 1em;
  font-weight: bold;
}
.question {
  font-weight: bold;
  text-align: center;
  font-size: 30px;
}

.cards {
  height: 143px;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  color: blue;
}

.text-question {
  margin-top: 1em;
}

.father-text {
  height: 30em;
  border: 1px solid rgba(192, 192, 192, 0.3);
  margin-top: 2em;
  margin-left: 120px;
  margin-right: 120px;
  padding-left: 100px;
  padding-right: 100px;
  background-color: rgba(192, 192, 192, 0.1);
}

@media (max-width: 900px) {
  .father-text {
    height: 30em;
    border: 1px solid rgba(192, 192, 192, 0.3);
    margin-left: 80px;
    margin-right: 80px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .text-question {
    margin-top: 1em;
  }

  .cards {
    height: 190px;
    font-size: 25px;
  }
}

@media (max-width: 600px) {
  .father-text {
    height: 30em;
    border: 1px solid rgba(192, 192, 192, 0.3);
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .text-question {
    margin-top: 2em;
  }
}
</style>
